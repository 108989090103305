// menu 模块接口列表示例
import {
    resquest
} from './base'
import axios from '../http' // 导入http中创建的axios实例 
import Qs from 'qs'


export const jn_scuser_man_vote = {
    get_list(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_list`, data2);
    },
    get_com_list(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_com_list`, data2);
    },
    get_record_list(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_record_list`, data2);
    },
    get_record_list_me(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_record_list_me`, data2);
    },
    get_record_add(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_record_add`, data2);
    },
    get_record_info(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_record_info`, data2);
    },
    get_record_tijiao_do(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_record_tijiao_do`, data2);
    },
    get_record_del_do(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_man_vote_pager/get_record_del_do`, data2);
    },
} 
