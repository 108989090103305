<template>
  <div class="hu_notice_box">
    <van-nav-bar title="资料上报" left-text="返回" left-arrow fixed placeholder @click-left="$router.push('personalInfo')">
    </van-nav-bar>
    <van-row>
      <van-col :span="24" style="margin-left: 0px;margin-right: 0px;">
        <div v-for="(option,index) in dataf" v-bind:key="index" style="padding-top: 20px;text-align: left;padding-left: 20px;">
          <van-button type="primary" size="small" @click="show_com(option)" style="margin-left: 10px;font-size: 10px;">
            {{option.vpname}}
          </van-button>
        </div>

      </van-col>
      <van-col :span="24">
        <van-cell-group>
          <van-cell v-for="(item,index) in data" v-bind:key="index">
            <template #title>
              <span class="custom-title" @click="tu_show(item.pkguid)">[{{item.cgpath}}]{{item.cgname}}
                <br />
                {{item.vppname}}</span>
            </template>
            <template #label>
              <van-tag mark type="warning" v-if="item.vrpischeck==0">仅保存</van-tag>
              <van-tag mark type="success" v-if="item.vrpischeck==1">已提交</van-tag>
              时间： {{item.vrptjtime}}
            </template>
          </van-cell>
        </van-cell-group>
        <div v-if="data.length==0">
          <van-empty description="暂无" />
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col :span="24">
        <van-pagination v-model="currentPage" :page-count="currentPageall" mode="simple" @change="changepage" />
      </van-col>
    </van-row>
    <van-popup v-model:show="show" position="bottom" :style="{ height: '50%' }">
      <van-search v-model="scname" show-action label="学校" placeholder="请输入搜索关键词" @search="onSearchc">
        <template #action>
          <div @click="onSearchc">搜索</div>
        </template>
      </van-search>
      <div>
        <van-row v-for="(item,index) in cdata" v-bind:key="index" style="    padding: 10px;">
          <van-col span="20">{{item.retitle}}</van-col>
          <van-col span="4">
            <van-button type="primary" @click="selcom_add(selt,item)" size="mini">选择</van-button>
          </van-col>
        </van-row>
        <div v-if="cdata.length==0">
          <van-empty description="暂无" />
        </div>
        <van-pagination v-model="currentPage_com" :page-count="currentPageall_com" mode="simple" @change="changepage_com" />
      </div>
      <div style="height:100px;"></div>
    </van-popup>
    <div style="height:100px;"></div>
  </div>
</template>

<script> 
import { Dialog, Notify } from 'vant';
import { jn_scuser_man_vote } from "@/api/jn_scuser_man_vote.js";
export default {
  data() {
    return {
      loading_sk: false,
      sdatetime: [],
      show: false,
      sdata: {},
      scname: '',
      selt: {},
      cdata: [],
      data: [],
      dataf: [],
      currentPage: 1,
      currentPageall: 1,
      currentPage_com: 1,
      currentPageall_com: 1,
    };
  },
  created() {
    this.jn_load_f();
    this.jn_load();
    //console.log(JSON.stringify(columns));
  },
  methods: {
    jn_load_f() {
      jn_scuser_man_vote.get_list().then(response => {
        console.log("api_vote.getlist=" + response.data);
        this.dataf = response.data.mydata.data;
      });
    },
    changepage(data) {
      this.sdata.page = data;
      this.jn_load();
      console.log(data);
    },
    show_com(data) {
      this.show = true;
      this.selt = data;
    },
    onSearchc(page) {
      console.log("onSearchc", this.scname);
      var dd = { cname: this.scname, page: page };
      jn_scuser_man_vote.get_com_list(dd).then(response => {
        if (response.data.statusCode === "200") {
          this.cdata = response.data.mydata.data;
          this.currentPageall_com = response.data.mydata.pageall;
          this.currentPage_com = response.data.mydata.page;
        }
      });
    },
    changepage_com(data) {
      this.onSearchc(data);
    },
    selcom_add(sdata, data) {

      Dialog.confirm({
        title: '确认',
        message:
          '确定要选择【' + data.retitle + '】吗 ?',
      })
        .then(() => {
          // on confirm 
          var dd = { guid: sdata.pkguid, xguid: data.pkguid };
          jn_scuser_man_vote.get_record_add(dd).then(response => {
            if (response.data.statusCode === "200") {
              this.tu_show(response.data.mydata.pkguid);
              return true;
            }
          });
        })
        .catch(() => {
          // on cancel 
        });

    },
    toedit(guid) {
      this.$router.push('/list_man_vote_show?guid=' + guid);
    },
    jn_load() {
      this.loading_sk = true;
      jn_scuser_man_vote.get_record_list_me(this.sdata).then(response => {
        console.log("api_vote.getlist=" + response.data);
        this.data = response.data.mydata.data;
        this.currentPageall = response.data.mydata.pageall;
        this.currentPage = response.data.mydata.page;
        this.sdata.page = response.data.mydata.page;
        this.sdata.pagesize = response.data.mydata.pagesize;
        this.loading_sk = false;
        //this.pagination.current = 1;//response.data.mydata.m_all 
      });
    },
    handleTableChange(page, pageSize) {
      //console.log("handleTableChange");
      //console.log("this.pagination.pageSize=" + this.pagination.pageSize);
      //console.log("page=" + page.current);
      //console.log("pageSize=" + pageSize);
      //console.log(JSON.stringify(page));
      console.log(JSON.stringify(pageSize));
      this.sdata.page = page.current;
      this.sdata.pagesize = page.pageSize;
      this.jn_load();
    },
    my_handleSearch() {
      console.log("my_handleSearch：" + JSON.stringify(this.sdata));
      this.sdata.page = 1;
      this.jn_load();
    },
    my_handleclear() {
      this.sdata = {};
      this.sdatetime = "";
    },
    tu_show(guid) {
      this.$router.push('/list_man_vote_show?guid=' + guid);
    },
  }
};
</script>

<style>
</style>
